import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { colors, helpers, typography, mediaQueries as mq } from 'src/styles';
import Image from 'src/components/Image';
import Arrow from 'src/svg/icon-arrow.svg';
import ScrollEntrance from 'src/components/ScrollEntrance';

const LargeLinkList = ({ list }) => (
  <StyledUl>
    {list &&
      list.map(({ image, url, linkText }, i) => (
        <ScrollEntrance key={i}>
          <li>
            <Link className="arrow-link" to={url}>
              <Image image={image} animate={false} />
              <div className="text">
                {linkText}
                <Arrow />
              </div>
            </Link>
          </li>
        </ScrollEntrance>
      ))}
  </StyledUl>
);

const transitionOptions = '.45s cubic-bezier(0.44, 0.24, 0.16, 1.00)';

const StyledUl = styled.ul`
  margin-top: 40px;

  li {
    margin-bottom: 20px;

    ${mq.largeAndUp} {
      margin-bottom: 40px;
    }
  }

  .gatsby-image-wrapper {
    border-radius: 50%;
    border: 4px solid transparent;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: border-color ${transitionOptions},
      transform ${transitionOptions}, box-shadow ${transitionOptions};
    width: 75px;

    ${mq.mediumAndUp} {
      width: 100px;
    }
  }

  .text {
    align-items: center;
    display: flex;
    margin-left: 20px;
    transition: transform ${transitionOptions}, color ${transitionOptions};
    ${typography.h2}
    ${helpers.responsiveStyles('font-size', 96, 86, 42, 32)}

    ${mq.mediumAndUp} {
      margin-left: 40px;
    }
  }

  .arrow-link {
    align-items: center;
    display: flex;

    svg {
      margin-left: 10px;
      opacity: 0;
      height: 52px;
      width: 54px;
      transition: margin ${transitionOptions}, opacity ${transitionOptions};

      * {
        fill: ${colors.green};
      }
    }

    &:focus,
    &:hover {
      .text {
        color: ${colors.green};
        transform: translate(20px, 0);
      }

      svg {
        margin-left: 40px;
        opacity: 1;
      }

      .gatsby-image-wrapper {
        border-color: ${colors.green};
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: scale(1.1);
      }
    }
  }
`;

export default LargeLinkList;
