import React from 'react'
import { Link } from 'gatsby'
import { mediaQueries as mq, colors } from 'src/styles'
import styled from '@emotion/styled'
import Container from 'src/components/Container'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Arrow from 'src/svg/icon-arrow.svg'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  background: ${ colors.green };
  color: ${ colors.white };
  display: block;
  padding: 24px 0;
  position: relative;

  ${ mq.largeAndUp } {
    padding: 70px 0;
  }
`
const ease = `cubic-bezier(0.44, 0.24, 0.16, 1.00)`;
const duration = `.45s`;
const titleHoverTransition = `
  transition: transform ${duration} ${ease};
  ${Wrapper}:hover & {
    transform: translate3d(20px, 0, 0);
  }
`
const Title = styled.h3`
  color: ${ colors.white };
  font-size: 36px;
  ${titleHoverTransition}
  transition-delay: .15s;


  ${ mq.largeAndUp } {
    font-size: 96px;
  }
`
const Subtitle = styled.div`
  align-items: center;
  display: flex;
  ${titleHoverTransition}

  ${ mq.largeAndUp } {
    font-size: 32px;
  }
`
const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  transition: width ${duration} ${ease};
  width: 0;

  ${Wrapper}:hover & {
    width: 100%;
  }
`
const ArrowIcon = styled(Arrow)`
  height: 1em;
  width: 1em;
  margin-left: 10px;
  line-height: 1em;
`
export default function NextSectionHome({title, subtitle, link}) {
  return (
    <ScrollEntrance>
      <Wrapper>
        <Container>
          <Link to={link}>
            <Subtitle className={`subtitle`}>
              <div>{subtitle}</div>
              <ArrowIcon />
            </Subtitle>
            <Title className="title">{title}</Title>
            <Overlay className="overlay" />
          </Link>
        </Container>
      </Wrapper>
    </ScrollEntrance>
  )
}

NextSectionHome.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.string
}