import { mediaQueries as mq, colors } from 'src/styles'
import styled from '@emotion/styled'
import Container from 'src/components/Container'

export const ContentContainer = styled(Container)`
  max-width: 970px;
  margin: 0 auto;
  padding: 0 0 40px;

  ${ mq.mediumAndUp } {
    padding: 0 0 80px;
  }

  &:first-of-type {
    padding-top: 50px;

    ${ mq.mediumAndUp } {
      padding-top: 80px;
    }
  }
`
export const ContentHeadline = styled.h3`
  font-weight: bold;
  color: ${ colors.green };
  font-size: 22px;

  ${ mq.largeAndUp } {
    font-size: 42px;
  }
`

export const ContentBody = styled.h3`
  p:last-of-type {
    margin-bottom: 0;
  }
`
