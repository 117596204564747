import React from 'react'
import styled from '@emotion/styled'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { ContentContainer } from 'src/components/HomepageStyles'
import PropTypes from 'prop-types'
import VideoPlayer from './VideoPlayer'

const VideoBackground = styled.div`
  background: rgba(0, 0, 0, .9);
`

export default function VideoPlayerHome({videoId, coverVideo = false}) {
  return (
    <VideoBackground>
      <ScrollEntrance>
        <ContentContainer className="content-container">
          <VideoPlayer
            videoId={videoId}
            poster={coverVideo}
            posterType="video"
          />
        </ContentContainer>
      </ScrollEntrance>
    </VideoBackground>
  )
}

VideoPlayerHome.propTypes = {
  videoId: PropTypes.string
}